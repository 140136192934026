import React, { Component } from 'react'
import "./Footer.css"
export class Footer extends Component {
  render() {
    const topscroll =() =>{
     
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }
    return (
   <>
  
   <section id="contact" className="section-5 shadow mt-1 form contact footer-bg-new ">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 col-lg-4">
            <div className="footer-title-1">
              <h4>Address :</h4>
              <p>Pushpak Logistics LLP <br />1st Floor, Standard House, <br /> Opp. Celebration Hotel,<br /> Indira Marg, Jamnagar, <br /> Gujarat, India</p>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <div className="footer-title-2">
              <h4>Contact :</h4>
              <ul className="navbar-nav">
                <li className="nav-item"> <a className="nav-link" href="tel:9879193900"><i className="fa fa-phone pr-1" aria-hidden="true"></i> +91 9099411711
                  </a> </li>
                <li className="nav-item"> <a className="nav-link" href="mailto:oprs@pushpaklogistics.co.in"><i className="fa fa-envelope pr-1" aria-hidden="true"></i> cs@pushpaklogistics.co.in
                  </a> </li>
              </ul>
             
              <h4>Follow us :</h4>
              <ul className="navbar-nav social share-list ml-auto">
                                          <li className="nav-item">
                                              <a href="https://www.facebook.com/PushpakLogistics/" Target="_blank" className="nav-link"><i className="fab fa-facebook-f"></i></a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="https://twitter.com/pushpaklogist?s=11&t=yMNyxA8fWMvIIdnbbq9_wQ" className="nav-link" Target="_blank"><i className="fab fa-twitter"></i></a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="https://www.linkedin.com/in/pushpak-logistics-617946202" className="nav-link" Target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                          </li>
                                          <li className="nav-item">
                                              <a href="https://instagram.com/pushpak_logistics?igshid=YmMyMTA2M2Y=" className="nav-link" Target="_blank"><i className="fab fa-instagram"></i></a>
                                          </li>
                                      </ul>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-4 col-sm-12 col-sx-12">
            <div data-href="https://www.facebook.com/PushpakLogistics/" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" className="fb-page">
              <blockquote cite="https://www.facebook.com/PushpakLogistics/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/PushpakLogistics/">Pushpak Logistics Solutions LLP/ Pvt Ltd</a></blockquote>
            </div>
          </div>
        </div>
      </div>
    </section> 
    
    <footer>
      <section id="copyright" className="p-3 odd copyright">
        <div className="container">
          <div className="row">
            <div className="col-12  text-center">
              <p>© <span id="copyright1">2022</span>. All rights reserved. Pushpak Logistics. Powered by <a Target="_blank" href="http://adhir.info/">Adhir info</a></p>
            </div>
          </div>
        </div>
      </section>
    </footer>
    <div id="scroll-to-top" className="scroll-to-top">
            <a   onClick={topscroll} className="smooth-anchor">
                <i className="fas fa-arrow-up"></i>
            </a>
        </div>        
        
   </>
    )
  }
}

export default Footer