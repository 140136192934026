import React, { useState, useRef }  from "react";
import Axios from 'axios';
import "./Career.css"

// import React, {useRef} from 'react'

// const FileUploader = ({onFileSelect}) => {
//     const fileInput = useRef(null)

//     const handleFileInput = (e) => {
//         // handle validations
//         onFileSelect(e.target.files[0])
//     }

//     return (
//         <div className="file-uploader">
//             <input type="file" onChange={handleFileInput}/>
//             <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary"/>
//         </div>
//     )
    
// }
export default function Career() {
    
    
    // class FileInput extends React.Component {
    //     constructor(props) {
    //       super(props)
    //       this.uploadFile = this.uploadFile.bind(this);
    //     }
        
    //     uploadFile(event) {
    //         let file = event.target.files[0];
    //         console.log(file);
            
    //         if (file) {
    //           let data = new FormData();
    //           data.append('file', file);
    //           // axios.post('/files', data)...
    //         }
    //     }
    // }
    // const [selectedFile, setSelectedFile] = useState(null);
    const ip ="http://localhost:3001"
        const [status, setStatus] = useState("");
        const [resp, setresp] = useState([]);
        
        const handleSubmit = async (e) => {
          e.preventDefault();
          setStatus("Sending...");
          console.log(e.target.elements.name.value);
        //   console.log(e.target.resume.value)
          const { name, email, message,phone,dob,position } = e.target.elements;
          const career_form = e.target
        //   const resume = e.target.files[0]
          console.log(name, email, message)
          if(name.value==null || name.value==''){
             setStatus("Name field required");
          }
          else if(email.value=='' || email.value==null){
             setStatus("Email field required");
              
          }
          else if(phone.value=='' || phone.value==null){
              setStatus("phone field required");
              
            }
            else if(phone.value.length!=10){
                setStatus("Phone Number has 10 digits");
                
            }
            else if(dob.value=='' || dob.value==null){
                setStatus("dob field required");
                
            }
           
            else if(message.value=='' || message.value==null){
               setStatus("Message field required");
                
            }
            else if(message.value.length<=10){
               setStatus("Message must be at least 10 characters");
                
            }
          else{
              
              let details = {
                name: name.value,
                email: email.value,
                message: message.value,
                phone: phone.value,
                dob: dob.value,
                position: position.value,
                // resume:resume,
              };
            //   console.log('e.target:', e.target)
            //   const formData = new FormData(e.target);
            //     formData.append("data", details);
            //     formData.append("try", "trial; valie");
            //     formData.append("file", selectedFile);
            //   console.log('this is formdata:::', formData)
              const config = {     
                headers: { 'content-type': 'multipart/form-data' }
            }
              Axios.post(ip+`/api/career`, details,config).then((response) => {
                // console.log("success", response.data);
                setresp(response.data)
                console.log(response.data)
               
                
             if (resp.mail===1){
                //  console.log("success")
                 setStatus('sucessfully send');
                 e.target.reset();
              setTimeout(() => {
                        setStatus('');
                      }, 6000);
                        
                      clearTimeout('vmessage');
             }
             if(resp.mail===0){
                 setStatus('Something went wrong please try again');
            //    setTimeout(() => {
            //         setStatus('');
            //       }, 6000);
                
               
             }
              }).catch((error) => {
                console.log(error);
              });
              
          }
        }
    return (
        <>
         <section id="slider" className="hero p-0 odd featured">
              <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
                  <div className="swiper-wrapper">
  
                     
                      <div className="swiper-slide slide-center">
  
                       
                          <img src="assets/images/banner_img/all_banner.jpg" className="full-image banner-img"  alt="Banner" />
  
                          <div className="slide-content row text-center">
                              <div className="col-12 mx-auto inner">
  
                                
                                  {/* <!-- <nav data-aos="zoom-out-up" data-aos-delay="800" aria-label="breadcrumb">
                                      <ol className="breadcrumb">
                                          <li className="breadcrumb-item"><a href="index.html" style="color: black">Home</a></li>
                                          <li className="breadcrumb-item active" aria-current="page">Career</li>
                                      </ol>
                                  </nav> --> */}
                                  <h1 className="mb-0 title effect-static-text banner-color" >Career</h1>
                              </div>
                          </div>
                      </div>
  
                  </div>
              </div>
          </section>
          <section id="slider" className="hero p-0 odd">
              <div className="container">
                  <div className="swiper-wrapper ">
                      <div className="swiper-slide slide-center">
                          <div className="slide-content row">
                              <div className="col-12 mx-auto inner">
                                          <h1 className="title effect-static-text mt-5 pt-4" style={{color:"#21333e"}}>Work with us</h1>
                                          <form  className="nexgen-simple-form" onSubmit={handleSubmit}>
                                              <input type="hidden" name="section" value="nexgen_form"/>
                                              <input type="hidden" name="reCAPTCHA"/>
                                              <div className="row form-group-margin shadow ">
                                                  <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                      <input type="text" name="name" id="name"  className="form-control field-name" placeholder="Name" />
                                                  </div>
                                                  <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                      <input type="email" name="email" id="email" className="form-control field-email" placeholder="Email"/>
                                                  </div>
                                                  <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                      <input type="text" name="phone" id="phone" className="form-control field-phone" placeholder="Phone"/>
                                                  </div>
                                                  <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                      <input type="date" name="dob" id="dob" className="form-control field-phone" placeholder="Date of Birth"/>
                                                  </div>
                                                  <div className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                      <input type="text" name="position" id="position" className="form-control field-phone" placeholder="Position"/>
                                                  </div>  
                                                  {/* <div  className="col-12 col-md-6 col-lg-4 m-0 p-2 input-group">
                                                      <label for="exampleFormControlFile1">Upload Resume</label>
                                                      <input type="file" name="resume" defaultvalue={selectedFile} onChange={(e) => setSelectedFile(e.target.files[0])}  className="form-control-file" id="resume"/> */}
                                                      {/* <FileUploaded
                                                        onFileSelectSuccess={(file) => setSelectedFile(file)}
                                                        onFileSelectError={({ error }) => alert(error)}
                                                        /> */}
                                                  {/* </div> */}
                                              
                                                  <div className="col-12 m-0 p-2 input-group">
                                                      <textarea name="message" id="message"  className="form-control field-message" placeholder="Message"></textarea>
                                                  </div>
                                                  <div className="col-12 col-12 m-0 pl-md-2">
                                                      <span className="text-white">{status}</span>
                                                  </div>
                                                  <div className="col-12 input-group m-0 p-2">
                                              
                                                      <button  style={{color:"#fff"}} type="submit" className="btn btn-career">Apply Now</button>
                                                  </div>
                                              </div>
                                          </form>                 
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <br/>
        </>
      )
}
