import "./Ordertracking.css";
import React, { useState } from "react";
import axios from "axios";
export default function Ordertracking() {
  const [order, setsearchval] = useState("");
  const [orderinfo, setorder] = useState(null);

  const data = {
    order: order,
  };
  // const ip = "http://localhost:3001"
  // const ip = "http://192.168.68.21:3001"
  
  const ip = "http://13.127.252.135:3001"
  const displaysearch = () => {
    console.log(order);
    axios.post(ip+`/api/order`, data).then((response) => {
        console.log("success", response.data);
        setorder(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(orderinfo);
  if (orderinfo != null) {
    if (orderinfo.arrival_status === 1) {
      orderinfo.heading = "Container arrived and delivery In-Progress";

      orderinfo.order_heading = "Ordered";

      orderinfo.lr_heading = "Order Assigned";

      orderinfo.portin_heading = "Gate-In Completed";

      orderinfo.sail_heading = "Vessel Sailed";

      orderinfo.arrival_heading = "Vessal Arrived";
    } else if (orderinfo.sail_status === 1) {
      orderinfo.heading = "Container sailed and in Transit";

      orderinfo.order_heading = "Ordered";

      orderinfo.lr_heading = "Order Assigned";

      orderinfo.portin_heading = "Gate-In Completed";

      orderinfo.sail_heading = "Vessel Sailed";

      orderinfo.arrival_heading = "Vessel in Transit";
    } else if (orderinfo.portin_status === 1) {
      orderinfo.heading = "Container is in port and to be loaded on to Vessel";

      orderinfo.order_heading = "Ordered";

      orderinfo.lr_heading = "Order Assigned";

      orderinfo.portin_heading = "Gate-In Completed";

      orderinfo.sail_heading = "Vessel Loading in Progress";

      orderinfo.arrival_heading = "Vessel Arrival";
    } else if (orderinfo.lr_status === 1) {
      orderinfo.heading = "Container will reach the Port for Vessel Loading";

      orderinfo.order_heading = "Ordered";

      orderinfo.lr_heading = "Order Assigned";

      orderinfo.portin_heading = "Awaiting Gate-In";

      orderinfo.sail_heading = "Vessel Load";

      orderinfo.arrival_heading = "Vessel Arrival";
    } else if (orderinfo.order_status === 1){
      orderinfo.heading = "Container loading In-Progress";

      orderinfo.order_heading = "Ordered";

      orderinfo.lr_heading = "Loading Receipt in Progress";

      orderinfo.portin_heading = "Port Gate-In";

      orderinfo.sail_heading = "Vessel Load";

      orderinfo.arrival_heading = "Vessal Arrival";
    }else {
      orderinfo.heading = "No order/container matching query found";

      orderinfo.order_heading = "Ordered";

      orderinfo.lr_heading = "Loading Receipt in Progress";

      orderinfo.portin_heading = "Port Gate-In";

      orderinfo.sail_heading = "Vessel Load";

      orderinfo.arrival_heading = "Vessal Arrival";
    }
  }

  return (
    <>
      <section id="slider" className="hero p-0  odd featured">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <div className="swiper-slide slide-center ">
              <img
                src="assets/images/banner_img/all_banner.jpg"
                className="full-image banner-img"
                alt="banner-img"
              />

              <div className="slide-content row text-center">
                <div className="col-12 mx-auto inner">
                  <h1 className="mb-0 title  effect-static-text banner-color">
                    ORDER TRACKING
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid">
        <div className="container">
          <div className="card-vessel rounded row  shadow mt-4 mb-4 bg-white">
            <div className="search-box1 m-2 col-lg-9 col-md-12  col-xs-11 col-xl-9 d-flex mx-auto">
              {/* <i className="fas fa-search"></i> <!-- <button className="btn-search" onclick="ffocus()"><i className="fas fa-search"></i></button> --> */}
              <div className="col-12  d-flex">
             
                <input
                  type="text"
                  id="from-date"
                  value={order}
                  onChange={(event) => {
                    setsearchval(event.target.value);
                  }}
                  className="input-search1"
                  placeholder="Container No /Order No /LR No"
                />
                <button type="submit" className="btn search_icon" onClick={displaysearch}>
                  <i className="fas fa-search"></i>
                </button>
              
              </div>
            </div>
          </div>
        </div>
      </section>
      {orderinfo != null ? (
        <section className="container">
          <div className="card shadow   px-6 py-3">
            <h5 className=" p-3 mb-3 rounded m-0 vtilte">
              <i className="fa fa-ship h3 pl-3 pr-2"></i>Order Status{" "}
            </h5>
            <div className="mx-1">
            <h4 className="text-primary ">
                    {orderinfo.heading}
                  </h4>

              {orderinfo.arrival_status === 1 ? (
                <>
                  {/* <h4 className="text-primary text-center">
                    {orderinfo.heading}
                  </h4> */}

                  <div className="step step-active">
                    <div>
                      <div className="circle  ">
                        <i className="fa fa-clipboard-list"></i>
                      </div>
                    </div>
                    <div>
                      <div className="title-prog">
                        {orderinfo.order_heading} {orderinfo.order_date}
                      </div>
                      <div className="caption">
                      <b>Order Id: </b>{orderinfo.order_no}
                      </div>
                    </div>
                  </div>
                  <div className="step step-active">
                    <div>
                      <div className="circle ">
                        <i className="fa-solid fa-box"></i>
                      </div>
                    </div>
                    <div>
                      <div className="title-prog">
                        {orderinfo.lr_heading} {orderinfo.assign_date}
                      </div>
                      <div className="caption">
                        <b>LR&nbsp;No:</b>&nbsp;{orderinfo.lr_no},&nbsp;
                        <b>Container:</b>&nbsp;{orderinfo.container_no},
                        <b>Transporter:</b>&nbsp;{orderinfo.transporter},
                        <b>Truck_No:&nbsp;</b>&nbsp;{orderinfo.truck_no},&nbsp;
                        <b>Shipper</b>
                        {orderinfo.shipper}
                        <b>Size</b>:{orderinfo.container_size_type}
                        <b>Liner:</b>
                        {orderinfo.liner}
                      </div>
                    </div>
                  </div>
                  <div className="step step-active">
                    <div>
                      <div className="circle">
                        <i className="fa-solid fa-boxes-packing"></i>
                      </div>
                    </div>
                    <div>
                      <div className="title-prog">
                        {orderinfo.portin_heading} {orderinfo.portin_date}{" "}
                      </div>
                      <div className="caption">
                        {/* <ul className="d-flex list-item">
                          <li>
                            <b>POL:</b>
                            {orderinfo.pol}
                          </li>
                          <li>
                            {" "}
                            <b>POD:</b>
                            {orderinfo.pod}
                          </li>
                          <li>
                            <b>Vessel:</b>
                            {orderinfo.vessel}
                          </li>
                          <li>
                            <b>ETD:</b>
                            {orderinfo.etd}
                          </li>
                          <li>
                            <b>ETA</b>
                            {orderinfo.eta}
                          </li>
                        </ul> */}
                         <b>POL:</b>
                            {orderinfo.pol}, {" "}
                            <b>POD:</b>
                            {orderinfo.pod}, {" "} <b>Vessel:</b>
                            {orderinfo.vessel}, {" "} <b>ETD:</b>
                            {orderinfo.etd}, {" "}<b>ETA</b>
                            {orderinfo.eta}
                      </div>
                    </div>
                  </div>
                  <div className="step step-active">
                    <div>
                      <div className="circle">
                        <i className="fa-solid fa-calendar-week"></i>
                      </div>
                    </div>
                    <div>
                      <div className="title-prog">{orderinfo.sail_heading} {" "} {orderinfo.atd} </div>
                      <div className="caption">
                        <b>Vessel:</b>
                        {orderinfo.vessel},<b> Invoice_No:</b>
                        {orderinfo.cn_no}
                      </div>
                    </div>
                  </div>

                  <div className="step step-active">
                    <div>
                      <div className="circle">
                        <i className="fas fa-calendar-check"></i>
                      </div>
                    </div>

                    <div>
                      <div className="title-prog">
                        {orderinfo.arrival_heading} {orderinfo.disc_ata}
                      </div>
                      <div className="caption">
                        <b>Port:</b>
                        {orderinfo.pod}
                      </div>
                    </div>
                  </div>
                </>
              ) : (<>
                   
                   {
                      orderinfo.sail_status=== 1 ?(<>
                                                     {/* <h4 className="text-primary text-center">
                                                      {orderinfo.heading}
                                                      </h4> */}

                                                      <div className="step step-active">
                                                      <div>
                                                         <div className="circle  ">
                                                            <i className="fa fa-clipboard-list"></i>
                                                         </div>
                                                      </div>
                                                      <div>
                                                         <div className="title-prog">
                                                            {orderinfo.order_heading} {orderinfo.order_date}
                                                         </div>
                                                         <div className="caption">
                                                         <b>Order Id: </b>{orderinfo.order_no}
                                                         </div>
                                                      </div>
                                                      </div>
                                                      <div className="step step-active">
                                                      <div>
                                                         <div className="circle ">
                                                            <i className="fa-solid fa-box"></i>
                                                         </div>
                                                      </div>
                                                      <div>
                                                         <div className="title-prog">
                                                            {orderinfo.lr_heading} {orderinfo.assign_date}
                                                         </div>
                                                         <div className="caption">
                                                            <b>LR&nbsp;No:</b>&nbsp;{orderinfo.lr_no},&nbsp;
                                                            <b>Container:</b>&nbsp;{orderinfo.container_no},
                                                            <b>Transporter:</b>&nbsp;{orderinfo.transporter},
                                                            <b>Truck_No:&nbsp;</b>&nbsp;{orderinfo.truck_no},&nbsp;
                                                            <b>Shipper</b>
                                                            {orderinfo.shipper}
                                                            <b>Size</b>:{orderinfo.container_size_type}
                                                            <b>Liner:</b>
                                                            {orderinfo.liner}
                                                         </div>
                                                      </div>
                                                      </div>
                                                      <div className="step step-active">
                                                      <div>
                                                         <div className="circle">
                                                            <i className="fa-solid fa-boxes-packing"></i>
                                                         </div>
                                                      </div>
                                                      <div>
                                                         <div className="title-prog">
                                                            {orderinfo.portin_heading} {orderinfo.portin_date}{" "}
                                                         </div>
                                                         <div className="caption">
                                                            {/* <ul className="d-flex list-item">
                                                            <li>
                                                               <b>POL:</b>
                                                               {orderinfo.pol}
                                                            </li>
                                                            <li>
                                                               {" "}
                                                               <b>POD:</b>
                                                               {orderinfo.pod}
                                                            </li>
                                                            <li>
                                                               <b>Vessel:</b>
                                                               {orderinfo.vessel}
                                                            </li>
                                                            <li>
                                                               <b>ETD:</b>
                                                               {orderinfo.etd}
                                                            </li>
                                                            <li>
                                                               <b>ETA</b>
                                                               {orderinfo.eta}
                                                            </li>
                                                            </ul> */}
                                                             <b>POL:</b>
                                                      {orderinfo.pol}, {" "}
                                                      <b>POD:</b>
                                                      {orderinfo.pod}, {" "} <b>Vessel:</b>
                                                      {orderinfo.vessel}, {" "} <b>ETD:</b>
                                                      {orderinfo.etd}, {" "}<b>ETA</b>
                                                      {orderinfo.eta}
                                                         </div>
                                                      </div>
                                                      </div>
                                                      <div className="step step-active">
                                                      <div>
                                                         <div className="circle">
                                                            <i className="fa-solid fa-calendar-week"></i>
                                                         </div>
                                                      </div>
                                                      <div>
                                                         <div className="title-prog">{orderinfo.sail_heading}{" "}{orderinfo.atd}  </div>
                                                         <div className="caption">
                                                            <b>Vessel:</b>
                                                            {orderinfo.vessel},<b> Invoice_No:</b>
                                                            {orderinfo.cn_no}
                                                         </div>
                                                      </div>
                                                      </div>

                                                   
                                                   </>):(<>
                                                         {
                                                            orderinfo.portin_status ===1 ?(<> 
                                                            {/* <h4 className="text-primary text-center">
                                                            {orderinfo.heading}
                                                            </h4> */}
      
                                                            <div className="step step-active">
                                                            <div>
                                                               <div className="circle  ">
                                                                  <i className="fa fa-clipboard-list"></i>
                                                               </div>
                                                            </div>
                                                            <div>
                                                               <div className="title-prog">
                                                                  {orderinfo.order_heading} {orderinfo.order_date}
                                                               </div>
                                                               <div className="caption">
                                                               <b>Order Id: </b>{orderinfo.order_no}
                                                               </div>
                                                            </div>
                                                            </div>
                                                            <div className="step step-active">
                                                            <div>
                                                               <div className="circle ">
                                                                  <i className="fa-solid fa-box"></i>
                                                               </div>
                                                            </div>
                                                            <div>
                                                               <div className="title-prog">
                                                                  {orderinfo.lr_heading} {orderinfo.assign_date}
                                                               </div>
                                                               <div className="caption">
                                                                  <b>LR&nbsp;No:</b>&nbsp;{orderinfo.lr_no},&nbsp;
                                                                  <b>Container:</b>&nbsp;{orderinfo.container_no},
                                                                  <b>Transporter:</b>&nbsp;{orderinfo.transporter},
                                                                  <b>Truck_No:&nbsp;</b>&nbsp;{orderinfo.truck_no},&nbsp;
                                                                  <b>Shipper</b>
                                                                  {orderinfo.shipper}
                                                                  <b>Size</b>:{orderinfo.container_size_type}
                                                                  <b>Liner:</b>
                                                                  {orderinfo.liner}
                                                               </div>
                                                            </div>
                                                            </div>
                                                            <div className="step step-active">
                                                            <div>
                                                               <div className="circle">
                                                                  <i className="fa-solid fa-boxes-packing"></i>
                                                               </div>
                                                            </div>
                                                            <div>
                                                               <div className="title-prog">
                                                                  {orderinfo.portin_heading} {orderinfo.portin_date}{" "}
                                                               </div>
                                                               <div className="caption">
                                                                  {/* <ul className="d-flex list-item">
                                                                  <li>
                                                                     <b>POL:</b>
                                                                     {orderinfo.pol}
                                                                  </li>
                                                                  <li>
                                                                     {" "}
                                                                     <b>POD:</b>
                                                                     {orderinfo.pod}
                                                                  </li>
                                                                  <li>
                                                                     <b>Vessel:</b>
                                                                     {orderinfo.vessel}
                                                                  </li>
                                                                  <li>
                                                                     <b>ETD:</b>
                                                                     {orderinfo.etd}
                                                                  </li>
                                                                  <li>
                                                                     <b>ETA</b>
                                                                     {orderinfo.eta}
                                                                  </li>
                                                                  </ul> */}
                                                                   <b>POL:</b>
                                                                  {orderinfo.pol}, {" "}
                                                                  <b>POD:</b>
                                                                  {orderinfo.pod}, {" "} <b>Vessel:</b>
                                                                  {orderinfo.vessel}, {" "} <b>ETD:</b>
                                                                  {orderinfo.etd}, {" "}<b>ETA</b>
                                                                  {orderinfo.eta}
                                                               </div>
                                                            </div>
                                                            </div>
                                                          
                                                            </>):(<>
                                                                                                   
                                                                                                   {
                                                         orderinfo.lr_status ===1 ?(<>
                                                                                                                                 
      
                                                            <div className="step step-active">
                                                            <div>
                                                               <div className="circle  ">
                                                                  <i className="fa fa-clipboard-list"></i>
                                                               </div>
                                                            </div>
                                                            <div>
                                                               <div className="title-prog">
                                                                  {orderinfo.order_heading} {orderinfo.order_date}
                                                               </div>
                                                               <div className="caption">
                                                               <b>Order Id:</b>{orderinfo.order_no}
                                                               </div>
                                                            </div>
                                                            </div>
                                                            <div className="step step-active">
                                                            <div>
                                                               <div className="circle ">
                                                                  <i className="fa-solid fa-box"></i>
                                                               </div>
                                                            </div>
                                                            <div>
                                                               <div className="title-prog">
                                                                  {orderinfo.lr_heading} {orderinfo.assign_date}
                                                               </div>
                                                               <div className="caption">
                                                                  <b>LR&nbsp;No:</b>&nbsp;{orderinfo.lr_no},&nbsp;
                                                                  <b>Container:</b>&nbsp;{orderinfo.container_no},
                                                                  <b>Transporter:</b>&nbsp;{orderinfo.transporter},
                                                                  <b>Truck_No:&nbsp;</b>&nbsp;{orderinfo.truck_no},&nbsp;
                                                                  <b>Shipper</b>
                                                                  {orderinfo.shipper}
                                                                  <b>Size</b>:{orderinfo.container_size_type}
                                                                  <b>Liner:</b>
                                                                  {orderinfo.liner}
                                                               </div>
                                                            </div>
                                                            </div>
                                                                                                                                   
                                                                                                                                   </>):(<>
                                                                                                                                            <div className="step ">
                                                                                                                                          <div>
                                                                                                                                             <div className="circle">
                                                                                                                                             <i className="fa-solid fa-box"></i>
                                                                                                                                             </div>
                                                                                                                                          </div>
                                                                                                                                          <div>
                                                                                                                                             <div className="title-prog">
                                                                                                                                                {orderinfo.lr_heading} 
                                                                                                                                             </div>
                                                                                                                                          
                                                                                                                                          </div>
                                                                                                                                          </div>
                                                                                                                                           
                                                                                                                                           </>)
                                                                                                        
                                                                                                        
                                                                                                      
                                                                                                   }
                                                                                                    <div className="step ">
                                                                                                <div>
                                                                                                   <div className="circle">
                                                                                                      <i className="fa-solid fa-boxes-packing"></i>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                   <div className="title-prog">
                                                                                                      {orderinfo.portin_heading} 
                                                                                                   </div>
                                                                                                 
                                                                                                </div>
                                                                                                </div>
                                                                                                   </>)
                                                            
                                                         }
                                                          <div className="step">
                                                            <div>
                                                               <div className="circle">
                                                                  <i className="fa-solid fa-calendar-week"></i>
                                                               </div>
                                                            </div>
                                                            <div>
                                                               <div className="title-prog">{orderinfo.sail_heading} </div>
                                                               
                                                            </div>
                                                            </div>
                                                         </>)
                      
                   } 
                    <div className="step ">
                    <div>
                      <div className="circle">
                        <i className="fas fa-calendar-check"></i>
                      </div>
                    </div>

                      <div>
                        <div className="title-prog">
                           {orderinfo.arrival_heading} 
                        </div>
                      </div>
                    </div>
                   </>)}
              
            </div>
          </div> 
       </section>
      ) : (
        <div></div>
      )}
    </>
  );
}
